const Palette = {
  식비_간식: "#F4CD72",
  주거_통신: "#EF9620",
  교통_차량: "#F49291",
  생활_마트: "#E14B54",
  의류_미용: "#835BA1",
  의료_건강: "#7DD0B6",
  교육_문화: "#30AC63",
  보험_세금: "#B6CC75",
  기타지출: "#D2D2D2",
  기타수입: "#D2D2D2",
  월급_용돈: "#F49291",
  투자_이자: "#835BA1",
};

export default Palette;
